body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2.5rem; 
}

h1 {
  position: relative;
  color: #00ff00;
  font-family: courier new;
}

img {
  position: relative;
  height: 200px;
  width: 200px;
}

#wrapper {
  margin-left:auto;
  margin-right:auto;
  width:500px;
  height:500px;
}

#padded-row {
  padding-top: 40px;
}

#padded-col {
  padding-top: 80px;
  padding-left: 175px;
}

#padded-col-mobile {
  padding-top: 0px;
  padding-left: 0px;
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
}

a.linkedin {
  color: #0e76a8;
}

a.github {
  color: white;
}

a.twitter {
  color: #1da1f2;
}

a.youtube {
  color: #eb3223;
}

a.gmail {
  color: #4285F4
}

/* style={{display: 'flex', justifyContent:'center', alignItems:'center', height: deviceHeight-100, width: deviceWidth-50}} */
